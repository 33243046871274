<template>
  <div>
    <section class="tables">
      <div class="row">
        <div class="col">
          <div class="card">
            <b-table
              :items="items_account_services_local"
              id="table-list"
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              small
            >
              <template v-slot:cell(servico)="data"><i v-if="data.item.arquivado" class="fa fa-archive text-danger"/> {{data.value}}</template>
              <template v-slot:cell(acoes)="data">
                <button v-if="data.item.arquivado" class="btn btn-primary btn-sm mr-2 btn-fw" @click="changeArquivado(false,data.index)" v-b-tooltip.html.left title="Restaurar">
                  <i class="fa fa-folder-open"></i>
                </button>
                <button v-if="!data.item.arquivado" class="btn btn-danger btn-sm mr-2 btn-fw" @click="changeArquivado(true,data.index)" v-b-tooltip.html.left title="Arquivar">
                  <i class="fa fa-archive"></i>
                </button>
              </template>
              <template v-slot:cell(preco)="data">R$ {{data.value}}</template>
            </b-table>
            <div class="row">
              <div class="col">
                <h6 class="col-12">
                  <small class="text-muted ml-2 mr-2">Itens por Página</small>
                  <div class="btn-group" role="group" aria-label="Basic example">
                    <button type="button" class="btn btn-outline-primary btn-sm">10</button>
                    <button type="button" class="btn btn-outline-primary btn-sm">25</button>
                    <button type="button" class="btn btn-outline-primary btn-sm">50</button>
                    <button type="button" class="btn btn-outline-primary btn-sm">100</button>
                  </div>
                </h6>
              </div>
              <div class="col">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="table-list"
                  align="center"
                ></b-pagination>
              </div>
              <div class="col" align="right">
                <b-btn variant="primary" class="btn btn-success btn-rounded btn-icon btn-sm text-white mr-3" v-b-tooltip.html.left title="Exportar para Excel">
                  <i class="fa fa-download"></i>
                </b-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>

const fields=
[
  { key: "IdDoServico", label: "ID",  sortable: false },
  { key: "servico", label: "Serviço",  sortable: true },
  { key: "preco", label: "Preço",  sortable: true },
  { key: "acoes", label: "Ações",  sortable: false }
];

import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="ti-arrow-down"></i>',
  descIcon: '<i class="ti-arrow-up"></i>'
});

export default {

  name: "plg-account-services-table",

  props: {
            items_account_services: {
              type: Array,
              required: true,
            }
          },

  components: {
  },

  data: function() {
    return {

      items_account_services_local: [],

      sortBy: "IdDoServico",
      perPage: 5,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      fields: fields,

      isCompact: true,
    };
  },


  methods: {

    changeArquivado(valor,id) {
      this.items_account_services_local[id].arquivado = valor;

    },


    setup(){

      this.items_account_services_local=this.items_account_services.slice();

    },

  },

  mounted() {

    this.setup();

  },


  computed: {
    rows() {
      return this.items_account_services_local.length;
    }
  },

};
</script>
